import bodybuilder from "bodybuilder";

/**
 * @typedef {import('bodybuilder').Bodybuilder} Query
 */

/**
 * @typedef {Object} RuleProperty
 * @property {boolean} disabled - Indicates if the rule is disabled.
 * @property {string} field - The field to be queried.
 * @property {any} value - The value to be used in the query.
 * @property {string} [operator] - The operator to be used in the query.
 */

const ruleHandlers = {
    number: numberHandler,
    string: termHandler,
    boolean: termHandler,
    nested: nestedHandler,
    dateRange: dateRangeHandler,
    range: rangeHandler,
    switchWithSelect: switchWithSelectHandler,
};

const shouldProcessRule = ({ properties: { value, disabled, operator } }) => {
    return !disabled && value !== "" && operator !== "";
};

/**
 *
 * @param {Query} query
 * @returns
 */
const mergeRuleWithQuery = (query, rule) => {
    if (rule.type === "group") {
        const groupRules = rule.properties.value;
        // IDK if this is the right way to handle this, but it works
        // my understanding of bodybuilder query library is limited
        const results = groupRules.map((rule) => mergeRuleWithQuery(bodybuilder(), rule).build().query).filter(Boolean); // For empty nested rules i get null should investigate why
        return query.filter("bool", { [rule.properties.exclude ? "must_not" : "should"]: results });
    } else {
        const ruleProperties = rule.properties;
        const ruleContext = rule.context || {};
        const value = ruleProperties.value;
        const handler = ruleHandlers[rule.properties.explicitFieldType || value.type || typeof value];
        return handler ? handler(query, ruleProperties, ruleContext) : query;
    }
};

function buildElasticQuery(formTree) {
    return formTree.filter(shouldProcessRule).reduce(mergeRuleWithQuery, bodybuilder()).build();
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function numberHandler(query, { value, field, operator }) {
    return operator === "eq" || operator === undefined
        ? query.filter("term", field, value)
        : query.filter("range", field, { [operator]: value });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function termHandler(query, { value, field, emptyAsFalse }) {
    if (value === false && emptyAsFalse) {
        return query.filter("bool", (b) => {
            b.orFilter("bool", "must_not", { exists: { field } });
            return b.orFilter("term", field, value);
        });
    } else {
        return query.filter("term", field, value);
    }
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function switchWithSelectHandler(query, { value }) {
    return query.filter("bool", (b) => {
        b.filter("term", value.switchValue.field, value.switchValue.value);
        if (value.switchValue.value && value.selectValue.value) {
            b.filter("match", value.selectValue.field, value.selectValue.value);
        }
        return b;
    });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function dateRangeHandler(query, { value, field }) {
    return value.to === "" && value.from === ""
        ? query
        : query.filter("range", field, {
              lte: value.from ? `now-${value.from}y/d` : undefined,
              gte: value.to ? `now-${value.to}y/d` : undefined,
          });
}

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function rangeHandler(query, { value, field }) {
    return value.from === "" && value.to === ""
        ? query
        : query.filter("range", field, {
              gte: value.from ? value.from : undefined,
              lte: value.to ? value.to : undefined,
          });
}

const contextualCriteriaHandler = (query, rule) => {
    const { value, field: criteriaName } = rule.properties;
    // TODO: remove magic strings
    // fields should come from config or be part of rule (maybe enriched by ruleContextEnricher)
    if (criteriaName === "medCriteria") {
        if (value === "NON_ACTIVE") {
            query.filter("exists", "medications.deletion_date");
        } else if (value === "ACTIVE") {
            query.notFilter("exists", "medications.deletion_date");
        }
    }
    return query;
};
/**
 * - 'dynamicCriteriaRules' are rules that might depend on context, might use differed fields specified in code, etc.
 * - 'independentRules' are rules that are not dependent on context, they are just simple rules where field matches elasticsearch field
 */
const separateRulesByRulesWithContextAndNot = (ruleProperties) => {
    return ruleProperties.reduce(
        (acc, rule) => {
            const { virtualField } = rule.properties;
            const key = virtualField ? "dynamicCriteriaRules" : "independentRules";
            return {
                ...acc,
                [key]: acc[key].concat(rule),
            };
        },
        { dynamicCriteriaRules: [], independentRules: [] },
    );
};

//#region Extract this logic
    const conversionMap = {
        "kreatinin_unit": {
            standardUnits: ["mikromolperll", "nmolperlml"],
            conversionFactor: 88.42,
        },
        "hemoglobin_unit": {
            standardUnits: ["mmolperl"],
            conversionFactor: 0.6206,
        },
    };

    const nonStandardUnitIsUsed = (rules, key) => {
        const { standardUnits, } = conversionMap[key.split(".")[1]];
        return rules.some(rule => rule.properties.field === key && !standardUnits.includes(rule.properties.value));
    }

const enrichNestedRules = (nestedRulesToProcess) => {
    const hemoglobinNonDefaultUnitIsUsed = nonStandardUnitIsUsed(nestedRulesToProcess, "labs.hemoglobin_unit");

    const kreatininNonDefaultUnitIsUsed = nonStandardUnitIsUsed(nestedRulesToProcess, "labs.kreatinin_unit");
    return nestedRulesToProcess.map((r) => {
        if (hemoglobinNonDefaultUnitIsUsed && r.properties.field === "labs.hemoglobin_in_mmolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: r.properties.value * conversionMap.hemoglobin_unit.conversionFactor,
                },
            };
        }
        if (kreatininNonDefaultUnitIsUsed && r.properties.field === "labs.kreatinin_in_mikromolperl") {
            return {
                ...r,
                properties: {
                    ...r.properties,
                    value: r.properties.value * conversionMap.kreatinin_unit.conversionFactor,
                },
            };
        }
        return r;
    });
}

//#endregion

/**
 * @param {Query} query
 * @param {RuleProperty} ruleProperty
 */
function nestedHandler(query, { value, field, exclude, nestedPath }) {
    const nestedFieldPath = nestedPath || field;
    const nestedRulesToProcess = value.nestedValues.filter(shouldProcessRule);
    const enrichedNestedRules = enrichNestedRules(nestedRulesToProcess);
    const { dynamicCriteriaRules, independentRules } = separateRulesByRulesWithContextAndNot(enrichedNestedRules);

    const medCriteriaNeverSubscribedIsPresent = dynamicCriteriaRules.some(
        (rule) => rule.properties.field === "medCriteria" && rule.properties.value === "NEVER_SUBSCRIBED",
    );

    // exclusive or (XOR) so if exclude is true we want to invert the medCriteriaNeverSubscribedIsPresent
    const notQuery = exclude ^ medCriteriaNeverSubscribedIsPresent;
    return independentRules.length === 0 || independentRules.every((v) => v.properties.value === "")
        ? query
        : query[notQuery ? "notQuery" : "query"]("nested", { path: nestedFieldPath }, (f) => {
              return f.query("bool", (b) => {
                      const queryWithProcessedDynamicCriteriaRules = dynamicCriteriaRules.reduce(contextualCriteriaHandler, b);
                      return independentRules.reduce(mergeRuleWithQuery, queryWithProcessedDynamicCriteriaRules);
              });
          });
}

export default buildElasticQuery;
